<template>
  <article id="wrap">
    <AppHeader>
      購入完了
    </AppHeader>
    <section class="contents pb_150">

      <div class="ta_c mt_20 mb_30"><img src="@/assets/img/buy/img_thank_you.png" alt="Thank you画像" class="thanks">
      </div>

      <div class="ta_c txt_12 mb_30" v-if="!isDigitalItem">
        ご注文ありがとうございました<br>
        確認メールを{{ email }}にお送りしました。<br>
        ご注文の商品が発送されましたらお知らせいたします。
      </div>

      <div class="bt_form mb_30" v-if="!isDigitalItem">
        <RouterLink :to="{name: 'talk', params: {talk_room: $store.state.talkRoom.lastReadTalkRoom}}">
          マスターページに戻る
        </RouterLink>
      </div>
      <div class="bt_form mb_30" v-if="isDigitalItem">
        <RouterLink :to="{name: 'talk', params: {talk_room: $store.state.talkRoom.lastReadTalkRoom}}">
          コンテンツをみる
        </RouterLink>
      </div>

    </section>
    <AppFooter fixed></AppFooter>
  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

export default {
  components: {AppHeader, AppFooter},
  props: {
    user_id: Number,
    artist_id: Number,
    code: String,
    price: Number,
    last_name: String,
    first_name: String,
    last_kana: String,
    first_kana: String,
    email: String,
    gender: String,
    birthday: String,
    zip: String,
    prefecture: String,
    city: String,
    street: String,
    building: String,
    tel: String,
    message: String,
    isDigitalItem: Boolean,
  },
  computed: {
    items() {
      return this.$store.state.cart;
    },
    totalPrice() {
      let price = 0;
      this.items.forEach(item => {
        price += item.price * item.value;
      });
      return price;
    },
  },
  methods: {
    next() {
      this.$router.push({name: 'cart-send'});
    },
  },
};
</script>